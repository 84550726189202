@font-face {
    font-family: 'Ubuntu';
    src: url('./fonts/Ubuntu-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Ubuntu';
    src: url('./fonts/Ubuntu-Italic.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'Ubuntu';
    src: url('./fonts/Ubuntu-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Ubuntu';
    src: url('./fonts/Ubuntu-BoldItalic.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'Ubuntu';
    src: url('./fonts/Ubuntu-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Ubuntu';
    src: url('./fonts/Ubuntu-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'Ubuntu';
    src: url('./fonts/Ubuntu-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Ubuntu';
    src: url('./fonts/Ubuntu-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
  }  