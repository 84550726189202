@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
    @apply min-h-screen;
  }
  
  body {
    @apply bg-background dark:bg-dark-background;
  }

  header {
    @apply bg-header dark:bg-dark-header shadow-md p-4 xl:mb-14;
  }
  
  .icon {
    @apply text-icon dark:text-dark-icon hover:text-primary active:text-dark-background;
  }
  
  h1 {
    @apply text-primaryText dark:text-dark-primaryText font-medium;
  }
  
  h2 {
    @apply text-secondaryText dark:text-dark-secondaryText;
  }

/* Saját gomb stílusok */
@layer components {
  .max-w-70pct {
    max-width: 70%;
  }

  .main-content {
    @apply flex flex-col min-h-screen;
  }

  .comment {
    @apply text-secondaryText dark:text-dark-secondaryText max-w-70pct mx-auto text-center break-words;
  }

  .footer {
    @apply bg-primary text-white py-1 text-center;
    @apply dark:bg-dark-header dark:text-dark-secondaryText;
    @apply sticky inset-x-0 bottom-0;
  }

  .custom-button {
    @apply text-buttonContent dark:text-dark-buttonContent border border-primary active:text-white active:bg-primary font-bold py-2 px-4 rounded-full inline-flex items-center justify-center transition-all duration-150 ease-in-out;
  }

  @media (min-width: 768px) { /* md méretű képernyők */
    .custom-button {
      @apply md:hover:bg-primary md:hover:text-white md:active:text-dark-buttonContent md:active:bg-dark-background md:dark:active:bg-dark-background;
    }
  }

  .custom-button-icon {
    @apply mr-2 -ml-1 h-5 w-5 text-current;
  }
}